export class User {
  id: number;
  username: string;
  usename: string;
  email: string;
  password: string;
  role: string;
  location_id: string;
  is_daily_report: boolean;
  is_weekly_report: boolean;
  is_monthly_report: boolean;
  dining_guest_count: boolean;
  modules: string;
  can_preview_invoice: boolean;
  orderhistory_print_receipt: boolean;
  print_void_orders: boolean;
  allow_locations: string;
  max_discount_permit: string;
  delivery_detail_enable: boolean;
  is_attributed_receipt: boolean;
  permissions: string;
  print_no_of_times: number;
  is_ingredient_disabled: string;
  image: string;
  web_image: string;
  is_brand_groups: boolean;
  is_extras_enble: boolean;
  display_modifier_titles: boolean;
  print_only_save_kds_orders: boolean;
  show_receipt_screen: boolean;
  order_type: boolean;
  open_dine_in: boolean;
  hide_cart_pay_button: boolean;
  has_void_order_permission: boolean;
  external_order_print_receipt: boolean;
  is_multi_location_allowed: boolean;
  qr_code_on_print_receipt: boolean;
  brand_id: any;
  is_channels_enable: boolean;
  channel_id: number;
  tip_amount: number;
}
